import React from "react";
import { Link, graphql } from "gatsby";

import { ArticleCard, MainTitle } from "../components/styled";

import { Bio, Layout, SEO } from "../components";

interface IProps {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          excerpt: string;
          fields: {
            slug: string;
          };
          frontmatter: {
            date: string;
            title: string;
            description: string;
          };
        };
      }>;
    };
  };
}

const BlogIndex: React.FC<IProps> = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <div>
        <SEO
          title="DeCodeNatura"
          keywords={[
            `blog`,
            `web development`,
            `javascript`,
            `react`,
            `working remote`,
          ]}
        />
        <Bio />
        <MainTitle>
          Thoughts on web development, working remotely, freelancing, career and
          everything in between.
        </MainTitle>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <ArticleCard key={node.fields.slug}>
              <small>{node.frontmatter.date}</small>
              <h3>
                <Link to={node.fields.slug}>{title}</Link>
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </ArticleCard>
          );
        })}
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
